import logo from './logo.svg';
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from './components/Homepage';
import PageEditor from './components/PageEditor';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact component={Homepage} />
        <Route path="/edit" component={PageEditor} />
      </Routes>
    </Router>
  );
}

export default App;
