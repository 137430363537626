import React from 'react';

function Homepage() {
  return (
    <div>
      <h1>Welcome to My CMS</h1>
      <p>Create and manage your magic website easily.</p>
    </div>
  );
}

export default Homepage;
